<template>
    <div>
        <div class="main-content">
            <div class="content">
                <div class="tab">
                    <el-button type="primary" @click="addBtn">创建邮局</el-button>
                </div>
                <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px" height="1%">
                    <el-table-column prop="school_name" label="学校名称"></el-table-column>
                    <el-table-column prop="courier_name" label="邮局名称" align="center"></el-table-column>
                    <el-table-column prop="province" label="省份" align="center"></el-table-column>
                    <el-table-column prop="city" label="城市" align="center"></el-table-column>
                    <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
                    <el-table-column prop="status" label="状态" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.status == 1?'正常':'禁用'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-link type="primary" :underline="false" @click="editBtn(scope.row.id)">编辑</el-link>
                            <el-link type="danger" :underline="false" @click="delBtn(scope.row.id)">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="pages-center"
                               :current-page="listPages.currentPageNum"
                               :page-size="listPages.eachPageNum"
                               layout="prev, pager, next, jumper"
                               :total="listPages.total"
                               @current-change="pageCurrentChange">
                </el-pagination>
                <el-dialog :title="this.addOrEdit === 'edit'?'编辑邮局':'创建邮局'" :visible.sync="dialogVisible" width="500px"
                           custom-class="custom-dialog"
                           :close-on-click-modal="false" @opened="openedDialog" @close="closeDialog">
                    <el-form :model="addForm" :rules="rules" ref="addForm" label-width="110px" class="demo-ruleForm">
                        <el-form-item label="学校名称" prop="school_name">
                            <el-input v-model="addForm.school_name"></el-input>
                        </el-form-item>
                        <el-form-item label="小邮局名称" prop="courier_name">
                            <el-input v-model="addForm.courier_name"></el-input>
                        </el-form-item>
                        <el-form-item label="账号" prop="username">
                            <el-input v-model="addForm.username"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" :prop="addOrEdit === 'add' ? 'password' : ''" >
                            <el-input v-model.trim="addForm.password" show-password :disabled="addOrEdit=='edit'"
                                      auto-complete="new-password"></el-input>
                        </el-form-item>
                        <el-form-item label="省市区" prop="value">
                            <el-cascader v-model="addForm.value" :options="areaArr" :props=keyObj clearable
                                         @change="handleChange" style="width: 350px;">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="详细地址" prop="detail_area">
                            <el-input v-model="addForm.detail_area"></el-input>
                        </el-form-item>
                        <el-form-item label="详细地址" prop="status" v-if="addOrEdit == 'edit'">
                            <el-switch
                                    v-model="addForm.status"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    active-value="1"
                                    inactive-value="0">
                            </el-switch>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" style="text-align: center">
                        <el-button @click="dialogVisible = false">取消</el-button>
                        <el-button type="primary" @click="submitForm('addForm')" style="margin-left: 30px">保存
                        </el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
    import {courierIndex, courierRead, courierCreate, courierUpdate, courierDel} from '@/config/apis'
    import {areaList} from '@/utils/area'

    export default {
        name: "PostOfficeManage",
        data() {
            return {
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                areaArr: [],
                keyObj: {value: 'areaName', label: 'areaName', children: 'children', checkStrictly: true},

                // 弹窗
                dialogVisible: false,
                addForm: {
                    user_id: null,
                    school_name: '',
                    courier_name: '',
                    username: '',
                    password: '',
                    value: [],
                    province: '',
                    city: '',
                    district: '',
                    detail_area: '',
                    status: '1',
                },
                rules: {
                    school_name: {required: true, message: '请输入学校名称', trigger: 'blur'},
                    courier_name: {required: true, message: '请输入邮局名称', trigger: 'blur'},
                    username: {required: true, message: '请输入账号', trigger: 'blur'},
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
                    ],
                    value: {required: true, message: '请选择省市区', trigger: 'change'},
                    detail_area: {required: true, message: '请输入详细地址', trigger: 'blur'},
                },
                addOrEdit: null,
            }
        },
        mounted() {
            this.getList()
            this.areaArr = areaList
        },
        methods: {
            getList() {
                let params = {
                    limit: this.listPages.eachPageNum,
                    page: this.listPages.currentPageNum,
                }
                courierIndex(params).then((res) => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                    if (this.listPages.total !== 0 && this.infoList.length === 0) {
                        this.listPages.currentPageNum--;
                        this.getList();
                    }
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            addBtn() {
                this.addOrEdit = 'add'
                this.dialogVisible = true
            },
            editBtn(id) {
                this.addOrEdit = 'edit'
                courierRead(id).then((res) => {
                    this.addForm = res.data
                    this.addForm = {
                        user_id: res.data.id,
                        username: res.data.username,
                        password: res.data.password,
                        school_name: res.data.school_name,
                        courier_name: res.data.courier_name,
                        province: res.data.province,
                        city: res.data.city,
                        district: res.data.district,
                        detail_area: res.data.detail_area,
                        value: [res.data.province, res.data.city],
                        status: JSON.stringify(res.data.status)
                    }
                    if(res.data.district){
                        this.addForm.value[2] = res.data.district
                    }
                })
                this.dialogVisible = true
            },
            delBtn(id) {
                this.$confirm('删除后将无法恢复，确定要删除？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    courierDel(id).then((res) => {
                        this.$message.success(res.msg)
                        this.getList()
                        this.dialogVisible = false
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },

            // 打开弹窗
            openedDialog() {

            },
            // 关闭弹窗
            closeDialog() {
                this.resetForm()
                this.addOrEdit = null
            },
            // 弹窗内保存
            submitForm(formName) {
                if (this.addForm.value.length < 2) {
                    this.$message.warning('请选择城市！')
                    return
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('school_name', this.addForm.school_name)
                        formData.append('username', this.addForm.username)
                        formData.append('courier_name', this.addForm.courier_name)
                        formData.append('province', this.addForm.province)
                        formData.append('city', this.addForm.city)
                        formData.append('detail_area', this.addForm.detail_area)
                        if (this.addForm.district) {
                            formData.append('district', this.addForm.district)
                        }
                        if (this.addOrEdit === 'add') {
                            formData.append('password', this.addForm.password)
                            courierCreate(formData).then((res) => {
                                this.$message.success(res.msg)
                                this.getList()
                                this.dialogVisible = false
                                this.resetForm()
                            }).catch((err) => {
                                console.error(err)
                            })
                        } else if (this.addOrEdit === 'edit') {
                            if (this.addForm.password) {
                                formData.append('password', this.addForm.password)
                            }
                            if (this.addForm.user_id) {
                                formData.append('id', this.addForm.user_id)
                            }
                            formData.append('status', this.addForm.status)
                            courierUpdate(formData).then((res) => {
                                this.$message.success(res.msg)
                                this.getList()
                                this.dialogVisible = false
                                this.resetForm()
                            }).catch((err) => {
                                console.error(err)
                            })
                        }
                    } else {
                        return false;
                    }
                });
            },
            // 重置表单
            resetForm() {
                this.$refs.addForm.resetFields();
                this.addForm = {
                    user_id: null,
                    school_name: '',
                    courier_name: '',
                    username: '',
                    password: '',
                    value: [],
                    province: '',
                    city: '',
                    district: '',
                    detail_area: '',
                    status: '1',
                }
            },
            handleChange(val) {
                if (val.length > 0) {
                    this.addForm.province = val[0]
                    this.addForm.city = val[1] ? val[1] : ''
                    this.addForm.district = val[2] ? val[2] : ''
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-content {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        display: flex;
        flex-direction: column;
    }

    .tab {
        text-align: right;
    }

    .content {
        flex: 1;
        height: 1%;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }
</style>